var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browse-tree"},[_c('el-card',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"title"},[_c('h3',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.addGenealogyForm)?_c('div',{staticClass:"numb"},[_vm._v(" 该族谱收录 "+_vm._s(_vm.addGenealogyForm.generationCount)+" 世，共计 "+_vm._s(_vm.addGenealogyForm.memberCount)+" 人 ")]):_vm._e()]),_c('div',{staticClass:"rightbtn"},[_c('el-button',{attrs:{"disabled":true,"type":"primary"}},[_vm._v("始祖")]),_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.changeAncestorID},model:{value:(_vm.MapNodesfrom.ancestorID),callback:function ($$v) {_vm.$set(_vm.MapNodesfrom, "ancestorID", $$v)},expression:"MapNodesfrom.ancestorID"}},_vm._l((_vm.AncestorData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.fullName,"value":item.id}})}),1),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.OptionValue),callback:function ($$v) {_vm.OptionValue=$$v},expression:"OptionValue"}},_vm._l((_vm.Option),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input',{attrs:{"type":"text","placeholder":_vm.OptionValue == 1
              ? '输入人物姓名进行查询'
              : '输入两个人物名字(或父亲名字+名字)并以空格分开，点查询显示人物关系'},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.OptionValue == 1 ? _vm.searchClick() : _vm.relationshipClick()}},model:{value:(_vm.Keyword),callback:function ($$v) {_vm.Keyword=$$v},expression:"Keyword"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.OptionValue == 1 ? _vm.searchClick() : _vm.relationshipClick()}}},[_vm._v("查询"),(_vm.searchData.length > 0)?_c('span',[_vm._v("（"+_vm._s(_vm.searchData.length)+"）")]):_vm._e()]),_c('el-dropdown',{staticClass:"showData",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('el-button',{attrs:{"type":"primary"}},[_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.searchData),function(item,index){return _c('el-dropdown-item',{key:item.id,class:{ 'select-item': _vm.selectIndex == index },attrs:{"command":{
                item: item,
                index,
              }}},[(_vm.searchIndex == 1)?_c('span',[_vm._v(_vm._s("第" + item.generationNum + "世，父亲：" + item.fatherFullName + "+" + item.fullName))]):_vm._e(),(_vm.searchIndex == 2)?_c('span',[_vm._v(" "+_vm._s("关系" + (index + 1) + "：" + item.desc)+" ")]):_vm._e()])}),1)],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"panelbody",staticClass:"panel-body"},[_c('div',{staticClass:"tooltip"},[_c('div',{staticClass:"item",staticStyle:{"cursor":"move"}},[_c('i',{staticClass:"el-icon-rank"}),_vm._v("移动 ")]),_c('div',{staticClass:"item enlarge",attrs:{"title":"ctrl+滚轮可缩放"},on:{"click":_vm.Enlarge}},[_c('i',{staticClass:"el-icon-zoom-in"}),_vm._v("放大 ")]),_c('div',{staticClass:"item",attrs:{"title":"ctrl+滚轮可缩放"},on:{"click":_vm.Narrow}},[_c('i',{staticClass:"el-icon-remove-outline"}),_vm._v("缩小 ")])]),_c('div',{staticClass:"linwrap",on:{"mousedown":function($event){$event.preventDefault();return _vm.mousedown.apply(null, arguments)},"mousewheel":function($event){$event.preventDefault();return _vm.mousewheel.apply(null, arguments)}}},[_c('div',{staticClass:"lineage-content",style:({
            transform:
              'scale(' +
              _vm.scaleValue +
              ') translate(' +
              _vm.XValue +
              'px,' +
              -_vm.YValue +
              'px)',
          })},[_c('div',{ref:"bodyRight",staticClass:"linshow"},[_c('div',{ref:"pnlCheckingBody",staticClass:"lleft",style:({
                transform:
                  'translate(' + _vm.transformpx + 'px,' + _vm.transformpy + 'px)',
              })},[_vm._l((_vm.LineageData),function(items,indexs){return _c('div',{key:indexs,attrs:{"data-id":items.id}},[_c('div',{staticClass:"tree-node",class:{
                    setp:
                      (_vm.searchIndex == 1 && items.id == _vm.searchId) ||
                      (_vm.searchIndex == 2 && _vm.Include(items.id).bools),
                  },style:({
                    position: 'absolute',
                    top: items.node.y - 186 + 186 + 'px',
                    left:
                      _vm.MapNodesfrom.width -
                      items.node.x -
                      _vm.defaultNodeSize.width -
                      (_vm.headerWidth + 7) +
                      'px',
                  })},[_c('span',{attrs:{"data-location":JSON.stringify(items.locations),"data-name":items.fullName}},[_vm._v(_vm._s(items.fullName))]),_c('div',{staticClass:"userinfo"},[_c('div',{staticStyle:{"color":"#000"}},[_c('b',[_vm._v(" "+_vm._s(items.fullName)+" ")])]),_c('span',[_vm._v(_vm._s(items.liveInfo))])])]),_c('span',{staticClass:"circle",style:({
                    left:
                      _vm.MapNodesfrom.width -
                      items.node.x -
                      24 -
                      (_vm.headerWidth + 12) +
                      'px',
                    top: items.node.y - 186 + 186 + 'px',
                  })}),_c('hr',{staticClass:"horizontal",style:({
                    left:
                      _vm.MapNodesfrom.width -
                      items.node.x -
                      items.node.childLineWidth +
                      items.node.width / 2 -
                      (_vm.headerWidth + 1 + 10) +
                      'px', //定义headerWidth = 42
                    top: items.node.childY - 200 + 14 + 186 + 'px', //201 - 0
                    width:
                      items.node.childLineWidth - items.node.width + 'px',
                  })}),_c('hr',{staticClass:"vertical",style:({
                    height: items.node.childY - items.node.y - 32 + 'px',
                    left:
                      _vm.MapNodesfrom.width -
                      items.node.x -
                      17 -
                      (_vm.headerWidth + 1 + 10) +
                      'px',
                    top: items.node.y - 152 + 186 + 'px',
                  })})])}),(_vm.searchIndex == 2 && _vm.searchData.length > 0)?_c('div',{staticClass:"dataline"},_vm._l((_vm.searchData[_vm.selectIndex]
                    .paths),function(lineitems,lineindexs){return _c('div',{key:lineindexs},[_c('hr',{staticClass:"shorizontal",style:({
                      left:
                        _vm.MapNodesfrom.width -
                        lineitems.x -
                        16 -
                        _vm.getLineW(lineindexs) -
                        (_vm.headerWidth + 1 + 10) +
                        'px', //定义headerWidth = 42
                      top: lineitems.y + 185 + 'px',
                      width: _vm.getLineW(lineindexs) + 'px',
                    })}),_c('hr',{staticClass:"svertical",style:({
                      left:
                        _vm.MapNodesfrom.width -
                        lineitems.x -
                        17 -
                        (_vm.headerWidth + 1 + 10) +
                        'px',
                      top: lineitems.y + 'px',
                      height: _vm.getLineH(lineindexs) + 'px',
                    })})])}),0):_vm._e()],2),_c('div',{staticClass:"itemlin",style:({
                transform: 'translate(' + 0 + 'px,' + _vm.transformpy + 'px)',
              })},[(_vm.memberSeparations.length > 0)?_c('div',{},_vm._l((_vm.memberSeparations[
                    _vm.memberSeparations.length - 1
                  ].generationNum),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item >= _vm.memberSeparations[0].generationNum),expression:"item >= memberSeparations[0].generationNum"}],key:index,staticClass:"linright",style:({
                    top: _vm.blockHeight + _vm.offsetY + 1 + 1 - 200 + 'px',
                    height: _vm.blockHeight + 'px',
                  })},[_vm._v(" 第"+_vm._s(_vm.GetLineageNum(item))+"世 ")])}),0):_vm._e()])]),_c('div',{staticClass:"background-line",style:({
              width: '100%',
              transform: 'translate(' + 0 + 'px,' + _vm.transformpy + 'px)',
            })},[(_vm.memberSeparations.length > 0)?_c('div',_vm._l((_vm.memberSeparations[
                  _vm.memberSeparations.length - 1
                ].generationNum),function(item,index){return _c('div',{key:index,staticClass:"background-item",style:({
                  top:
                    _vm.blockHeight +
                    _vm.offsetY +
                    1 -
                    200 +
                    _vm.blockHeight * (index + 1) +
                    'px',
                  width: `calc(100% + ${_vm.XValue * 2}px)`,
                })})}),0):_vm._e()]),(!_vm.LineageData)?_c('el-empty',{attrs:{"image-size":300,"description":"未加载家谱世系数据点击左边树节点加载"}}):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }